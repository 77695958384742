<template>
  <b-container>
    <validation-observer
      #default="{ invalid }"
    >
      <b-form>
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-for="initial-date"
                label="Data Inicial"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data Inicial"
                  rules="required|before-date"
                >
                  <b-form-input
                    id="initial-date"
                    v-model="request.startDate"
                    vid="StartDate"
                    :state="errors.length > 0 ? false:null"
                    type="date"
                  />

                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label-for="final-date"
                label="Data Final"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data Final"
                  :rules="`required|history-after-date:${request.startDate}`"
                >
                  <b-form-input
                    id="final-date"
                    v-model="request.endDate"
                    type="date"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-button
              class="d-block mx-auto"
              variant="primary"
              :disabled="invalid"
              @click="download"
            >
              Download
            </b-button>
          </b-row>
        </b-card>

      </b-form>
    </validation-observer>

  </b-container>
</template>

<script>
import {
    BForm, BContainer, BFormGroup, BFormInput, BButton, BRow, BCol, BCard,
} from 'bootstrap-vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

import TransactionHistoryService from '@/service/transactions-report/';

import '@validations';

export default {
    components: {
        BForm, BContainer, BFormGroup, BFormInput, BButton, BRow, BCol, BCard, ValidationObserver, ValidationProvider,
    },

    data() {
        return {
            request: {
                startDate: null,
                endDate: null,
            },
        };
    },

    methods: {
        async download() {
            const { request } = this;
            this.callLoading(true);
            const response = await TransactionHistoryService.getHistory(request);
            this.callLoading(false);

            const { status } = response;

            if (status === 204) {
                this.modalError('Não possui transações nessas datas');
                return;
            } if (status !== 200) {
                this.modalError('Erro ao gerar xlsx');
                return;
            }

            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'transacao.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();

            this.modalSuccess('Arquivo baixado com sucesso');
        },
    },
};
</script>
