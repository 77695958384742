import Thoth from '../base/thoth';

class TransactionReportService extends Thoth {
    getHistory({
        startDate,
        endDate,
    }) {
        return this.fetch({
            url: 'xlsx-generate',
            maxBodyLength: Infinity,
            params: {
                startDate,
                endDate,
            },
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/octet-stream',
            },
        });
    }
}

export default new TransactionReportService();
